import { observer } from "mobx-react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { Booking } from "../../../stores";
import { LicensePlate, ListItem, Margins, Title } from "../../../storybook";

const styles = StyleSheet.create({
  container: {
    paddingVertical: Margins.regular,
  },
  title: {
    marginVertical: Margins.regular,
  },
  row: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: Margins.small,
  },
  col: {
    flex: 1,
  },
  item: {
    flex: 1,
  },
});

interface Props {
  booking: Booking;
}
const BookingDirectStarted = (props: Props) => {
  const { booking } = props;
  const {
    vehicle,
    displayStartDate,
    displayStartTime,
    displayEndDate,
    displayEndTime,
  } = booking;

  const { t } = useTranslation();

  return (
    <>
      <Title level={2} style={styles.title}>
        <Trans>Successful, the gate will open</Trans>
      </Title>
      <View style={styles.row}>
        {vehicle ? (
          <View style={styles.col}>
            <ListItem
              title={t("License plate")}
              description={
                <LicensePlate
                  country={vehicle?.country}
                  code={vehicle?.licensePlate}
                />
              }
            />
          </View>
        ) : null}
        <View style={styles.col}>
          <ListItem
            title={t("Found vehicle")}
            description={vehicle?.description || t("Unknown vehicle")}
          />
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.col}>
          <ListItem
            title={t("Start time")}
            description={`${displayStartDate}\n${displayStartTime}`}
          />
        </View>
        <View style={styles.col}>
          <ListItem
            title={t("End time")}
            description={`${displayEndDate}\n${displayEndTime}`}
          />
        </View>
      </View>
      <View style={styles.row}>
        <ListItem
          style={styles.item}
          title={t("End parking")}
          description={t(
            `When you want to leave the parking spot, you can pay with the  My Way app, the payment QR codes on location or the tablet at the reception (depending on the methods available).`
          )}
        />
      </View>
      <View style={styles.row}>
        <ListItem
          style={styles.item}
          title={t("Park longer?")}
          description={t(
            `Download the My Way app and add your vehicle. Then you can easily extend your booking with the three dots at your booking screen.`
          )}
        />
      </View>
    </>
  );
};

export default observer(BookingDirectStarted);
