import { RouteProp } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import React, { useMemo, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";

import { Page, BookingDirectConfirm } from "../../components";
import { PublicStackParamList } from "../../routes/public";
import { Booking, useStore, withAnonymous } from "../../stores";
import { Button, Margins, Radius, Title } from "../../storybook";

const styles = StyleSheet.create({
  spot: {
    borderTopStartRadius: Radius.regular,
    borderTopEndRadius: Radius.regular,
    overflow: "hidden",
  },
  content: {
    paddingVertical: Margins.regular,
  },
  title: {
    marginBottom: Margins.regular,
  },
  row: {
    marginVertical: Margins.small,
  },
});

type PayNavigationProp = StackNavigationProp<
  PublicStackParamList,
  "PublicConfirm"
>;
type PayRouteProp = RouteProp<PublicStackParamList, "PublicConfirm">;

interface Props {
  navigation: PayNavigationProp;
  route: PayRouteProp;
}
const Pay = (props: Props) => {
  const { route, navigation } = props;
  const { bookingId, checkout } = route.params || {};
  const store = useStore();
  const booking = useMemo(
    () => new Booking(`bookings/${bookingId}`, {}, store),
    [bookingId]
  );
  const spot = useMemo(() => booking.spot, [booking.isLoaded]);
  const spotId = spot?.id;
  useEffect(() => {
    if (spotId) {
      store.history.back = () => {
        if (Platform.OS === "web") {
          window.location.href = `/public/end/${spotId}`;
        } else {
          navigation.navigate("PublicEnd", { spotId });
        }
      };
    }
  }, [spotId]);
  const { t } = useTranslation();
  if (!booking.isLoaded || !spot?.isLoaded) return null;

  const pay = () => {
    if (Platform.OS === "web") {
      window.location.href = checkout
        ? `/public/${checkout ? "checkout/" : ""}pay/${bookingId}`
        : `/public/scan/${bookingId}`;
    } else if (checkout) {
      navigation.navigate(checkout ? "PublicPayCheckout" : "PublicPay", {
        bookingId,
      });
    } else {
      navigation.navigate("PublicScan", { bookingId });
    }
  };

  return (
    <Page spot={spot}>
      <View style={styles.content}>
        <Title level={2} style={styles.title}>
          <Trans>Final check, is everything correct?</Trans>
        </Title>
        <BookingDirectConfirm booking={booking} />
        <Button
          title={t("Pay now")}
          right="arrowright"
          style={styles.row}
          onPress={pay}
        />
        {spotId ? (
          <Button
            left="arrowleft"
            title={t("Back")}
            onPress={() => {
              if (Platform.OS === "web") {
                window.location.href = `/public/end/${spotId}`;
              } else {
                navigation.navigate("PublicEnd", { spotId });
              }
            }}
          />
        ) : null}
      </View>
    </Page>
  );
};

export default withAnonymous(observer(Pay));
