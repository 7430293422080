import firebase from "firebase/compat/app";
import { Document } from "firestorter";

export interface LastScannedLicensePlateData {
  createdAt: firebase.firestore.Timestamp;
  licensePlate: string;
  licenseRef: string;
  country: string;
}

class LastScannedLicensePlate extends Document<LastScannedLicensePlateData> {
  get createdAt() {
    if (!this.data.createdAt) return undefined;
    return this.data.createdAt.toDate().getTime();
  }

  get code() {
    return this.data.licensePlate;
  }

  get country() {
    return this.data.country;
  }
}

export default LastScannedLicensePlate;
