import { RouteProp } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import React, { useState, ReactNode, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";

import useSpotAvailability from "./hooks/useSpotAvailability";
import useVehicleUpdater from "./hooks/useVehicleUpdater";
import { Page, SpotClosing, VehicleForm } from "../../components";
import {
  currencyToDisplayString,
  reportError,
  translatedError,
} from "../../helpers";
import { PublicStackParamList } from "../../routes/public";
import { Bookings, Spot, useStore, withAuthentication } from "../../stores";
import LastScannedLicensePlate from "../../stores/parking/spot/LastScannedLicensePlate";
import { sanitizeLicense } from "../../stores/parking/vehicle/vehicles";
import {
  Button,
  Margins,
  Title,
  ListItem,
  Text,
  Colors,
  Vehicle,
} from "../../storybook";

const styles = StyleSheet.create({
  content: { paddingVertical: Margins.regular },
  title: {
    marginBottom: Margins.small,
    textAlign: "center",
    paddingBottom: 16,
  },
  item: { marginVertical: Margins.small },
  row: { flexDirection: "row", gap: Margins.small },
});

type StartNavigationProp = StackNavigationProp<
  PublicStackParamList,
  "PublicStart"
>;
type StartRouteProp = RouteProp<PublicStackParamList, "PublicStart">;

interface Props {
  navigation: StartNavigationProp;
  route: StartRouteProp;
}

const ScannedStart = (props: Props) => {
  const { route, navigation } = props;
  const spotId = route.params?.spotId;
  const { t } = useTranslation();
  const store = useStore();
  const { auth } = store;

  const lastScannedLicensePlate = useMemo(
    () => new LastScannedLicensePlate(`lastScannedLicensePlate/${spotId}`),
    [spotId]
  );

  const spot = useMemo(() => new Spot(`spots/${spotId}`, {}, store), [spotId]);

  const { availability, closingAvailability } = useSpotAvailability(spot);
  const { getVehicle } = useVehicleUpdater();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ReactNode>();
  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  const [vehicleDescription, setVehicleDescription] = useState<
    string | undefined
  >("");

  const create = async () => {
    if (!vehicle || !vehicle.code || !vehicle.country) return;
    setLoading(true);

    try {
      if (auth.user?.id) {
        const bookings = new Bookings(
          { userId: auth.user.id, payment: "failed" },
          store
        );
        const failed = await bookings.fetch();
        if (failed.docs.length > 0) {
          throw new Error("You have open payments");
        }
      }

      const licenseRef = sanitizeLicense(vehicle.code);
      const bookings = new Bookings(
        { spotId, "vehicle.licenseRef": licenseRef },
        store
      );
      await bookings.fetch();

      let newBooking = bookings.hasDocs ? bookings.docs[0] : undefined;

      if (!newBooking) {
        const userId = auth.user?.id;
        if (!userId) return;
        const vehicleDoc = await getVehicle(vehicle);
        if (!vehicleDoc?.id) throw new Error("Failed to create vehicle");

        let endAt;
        if (availability && !availability.open) {
          endAt = availability.endAt;
        }

        const response = await bookings.request({
          endAt,
          spot,
          vehicleIds: [vehicleDoc.id],
        });
        newBooking = response.booking;
        console.info(`New booking: ${newBooking?.id}`);
      } else {
        console.info(`Active booking: ${newBooking.id}`);
      }

      await newBooking?.openGate("entrance");
      const bookingId = newBooking?.id || "";

      if (Platform.OS === "web") {
        window.location.href = `/public/started/${bookingId}?goBack=true`;
      } else {
        navigation.navigate("PublicStarted", { bookingId, goBack: true });
      }
    } catch (err) {
      const { message } = err as Error;
      console.error("Error: ", message);
      reportError(err as Error, { name: "public/start", type: "web" });
      setError(translatedError(message));
    } finally {
      setLoading(false);
    }
  };

  const spotRate = () => {
    const { hourly } = spot.rates;
    const displayHourlyRates = currencyToDisplayString(
      hourly.currency,
      hourly.value,
      true
    );
    const displayDailyRates = currencyToDisplayString(
      hourly.currency,
      hourly.daily ?? 0,
      true
    );

    return (
      <View style={styles.item}>
        <ListItem title={t("Hourly rate and daily price")} />
        <View style={{ flexDirection: "row" }}>
          <Text>{`${displayHourlyRates} / ${t("hour")}`}</Text>
          {hourly.daily && (
            <Text
              style={{ paddingHorizontal: 100 }}
            >{`${displayDailyRates} / 24 ${t("hour")}`}</Text>
          )}
        </View>
      </View>
    );
  };

  const licensePlateValid = (vehicle?.code?.length || 0) > 0;
  const isClosed =
    closingAvailability &&
    closingAvailability.startAt.getTime() ===
      closingAvailability.endAt.getTime();

  return (
    <Page spot={spot} tabletSizeScreen>
      <View style={styles.content}>
        <Title level={2} style={styles.title}>
          <Trans>Welcome to</Trans> {spot.name}
        </Title>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Title style={{ marginBottom: Margins.tiny }}>
            <Trans>Scanned license plate</Trans>
          </Title>
          <Title style={{ marginBottom: Margins.tiny, color: Colors.red }}>
            <Trans>Press a character to edit</Trans>
          </Title>
        </View>
        <View style={[styles.row, { justifyContent: "center" }]}>
          <VehicleForm
            value={vehicle?.code ? vehicle : lastScannedLicensePlate}
            onChange={(vehicle) => {
              const newVehicle = {
                code: vehicle.code,
                country: vehicle.country || lastScannedLicensePlate.country,
              };

              setVehicle(newVehicle);
              setVehicleDescription(vehicle.vehicle);
            }}
            huge
          />
        </View>
        <View>
          {vehicleDescription ? (
            <ListItem
              style={styles.item}
              title={t("Found vehicle")}
              description={vehicleDescription}
            />
          ) : null}
          {spotRate()}
          <SpotClosing availability={closingAvailability} spot={spot} />
        </View>
        <Button
          title={error || t("Start parking")}
          color={error ? "red" : undefined}
          centre="play"
          style={[styles.item, { alignSelf: "center" }]}
          size="large"
          disabled={!licensePlateValid || loading || isClosed}
          onPress={create}
          loading={loading}
        />
      </View>
    </Page>
  );
};

export default withAuthentication(observer(ScannedStart));
