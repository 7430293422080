import { RouteProp } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import React, { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";

import { Button, Margins } from "./../../storybook";
import { Page, BookingDirectStarted } from "../../components";
import { PublicStackParamList } from "../../routes/public";
import { Booking, useStore, withAnonymous } from "../../stores";

type BookingNavigationProp = StackNavigationProp<
  PublicStackParamList,
  "PublicStarted"
>;
type BookingRouteProp = RouteProp<PublicStackParamList, "PublicStarted">;

interface Props {
  navigation: BookingNavigationProp;
  route: BookingRouteProp;
}

const Started = (props: Props) => {
  const { route, navigation } = props;
  const store = useStore();
  const bookingId = route.params?.bookingId;
  const goBack = route.params?.goBack;
  const { t } = useTranslation();

  const booking = useMemo(
    () => new Booking(`bookings/${bookingId}`, {}, store),
    [bookingId]
  );
  const { spot } = booking;
  const spotId = spot?.id;

  useEffect(() => {
    if (spotId) {
      store.history.back = () => {
        if (Platform.OS === "web") {
          window.location.href = `/public/end/${spotId}`;
        } else {
          navigation.navigate("PublicEnd", { spotId });
        }
      };
    }
    if (goBack) {
      const timer = setTimeout(() => {
        navigation.navigate("PublicScannedStart", {
          spotId: booking.spot?.id ?? "",
        });
      }, 10000); //10 seconds

      return () => clearTimeout(timer);
    }
  }, [spotId, navigation, booking.spot, goBack]);

  if (booking.isLoaded && !booking.hasData) {
    // @ts-ignore
    navigation.navigate("Home");
    return;
  }

  return (
    <Page spot={spot}>
      <BookingDirectStarted booking={booking} />
      {spotId ? (
        <Button
          centre="arrowleft"
          title={t("Back")}
          style={{ marginTop: Margins.small }}
          size="large"
          onPress={() => {
            if (Platform.OS === "web") {
              window.location.href = `/public/scanned-start/${spotId}`;
            } else {
              navigation.navigate("PublicScannedStart", { spotId });
            }
          }}
        />
      ) : null}
    </Page>
  );
};

export default withAnonymous(observer(Started));
